enum TID {
  Container = "container-element",
  MainElement = "main-element",
  InfoCard = "info-card",
  InfoCardTitle = "info-card-title",
  InfoCardContentText = "info-card-content",
  InfoCardEmailAction = "info-card-email-action",
  Footer = "footer-info-text",
}

export default TID;
