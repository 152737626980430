import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  Link,
  ThemeProvider,
  Typography,
} from "@mui/material";
import TID from "./resources/test.util";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        data-testid={TID.Container}
        sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <Box data-testid={TID.MainElement} component="main" sx={{ flex: 1 }}>
          <Card
            data-testid={TID.InfoCard}
            variant="outlined"
            sx={{
              my: 5,
              borderWidth: 1,
              borderStyle: "solid",
              borderImage: "linear-gradient(45deg, blue, red) 1",
            }}
          >
            <CardHeader title="Harnicode" />
            <CardContent>
              <Typography>
                Let Harnicode build your software, so you can focus on your
                business.
              </Typography>
            </CardContent>
            <CardActions>
              <Box px={1}>
                <Link
                  href="mailto:harnicode@gmail.com?subject=Software Project"
                  sx={{ textDecoration: "none" }}
                >
                  Shoot us an email
                </Link>
              </Box>
            </CardActions>
          </Card>
        </Box>
        <Box
          data-testid={TID.Footer}
          component="footer"
          sx={{ my: 2, mx: "auto" }}
        >
          <Typography align="center" variant="caption">
            This website is a work in progress.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
